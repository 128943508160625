$color--grass: rgb(100, 195, 155);
$color--ocean: rgb(40, 50, 120);
$color--space: rgb(65, 90, 110);

* {
  box-sizing: border-box;
  margin-block-end: 0;
  margin-block-start: 0;
  margin-inline-end: 0;
  margin-inline-start: 0;
}

html,
body,
#root {
  margin: 0;
  min-height: 100%;
  padding: 0;
  position: relative;
}

html {
  font-size: 62.5%;
}

body {
  background-color: white;
  color: black;
  font-family: "Roboto", sans-serif;
  font-size: 1.6rem;
}

#root {
  margin-left: auto;
  margin-right: auto;
  max-width: 720px;
  padding: 4rem;
}

h1 {
  font-size: 2.4rem;
  margin-bottom: 2rem;
  text-align: center;
}

h2 {
  font-size: 1.8rem;
  margin-bottom: 2rem;
  &+& {
    margin-top: 2rem;
  }
}

p {
  font-size: 1.4rem;
  text-align: center;
  &+& {
    margin-top: 2rem;
  }
}

.form--logo {
  display: block;
  height: 20px;
  margin-bottom: 4rem;
  margin-left: auto;
  margin-right: auto;
  vertical-align: middle;
  width: auto;
}

.form--control, %form--control {
  position: relative;
  width: 100%;
  &+& {
    margin-top: 2rem;
  }
  &[data-required] label:after {
    color: $color--ocean;
    content: " *";
    display: inline;
  }
}

.form--row {
  @extend %form--control;
  display: grid;
  grid-gap: 40px;
  grid-template-columns: repeat(12, 1fr);
}

.form--column {
  grid-column: span 6;
}

@media screen and (max-width: 1080px) {
  .form--row {
    grid-template-columns: repeat(1, 1fr);
  }

  .form--column {
    grid-column: span 1;
    &+& {
      margin-top: -2rem;
    }
  }
}

.form--fieldset {
  border-radius: 2px;
  border: 1px solid rgba($color--space, 0.25);
  margin-top: 4rem;
  padding: 2rem;
  width: 100%;
}

.form--label {
  cursor: pointer;
  display: block;
  font-size: 1.2rem;
  font-weight: bold;
  letter-spacing: 0.1rem;
  line-height: 1.2rem;
  margin-bottom: 1.2rem;
}

.form--description {
  display: block;
  font-size: 1.2rem;
  margin-top: 0.5rem;
}

.form--input, %form--input {
  background-color: white;
  border-bottom: 2px solid rgba($color--space, 0.5);
  border-left: 1px solid rgba($color--space, 0.5);
  border-radius: 2px;
  border-right: 1px solid rgba($color--space, 0.5);
  border-top: 1px solid rgba($color--space, 0.5);
  font-family: inherit;
  font-size: 1.4rem;
  outline: 0;
  padding: 1.2rem 1rem;
  width: 100%;
  position: relative;
  &:focus {
    border-color: $color--space;
  }
}

.form--select {
  position: relative;
  z-index: 0;
  &:after {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid $color--space;
    content: "";
    display: block;
    height: 0;
    margin-top: -3px;
    position: absolute;
    right: 1.2rem;
    top: 50%;
    width: 0;
    z-index: 1;
  }
  select {
    @extend %form--input;
    appearance: none;
    background-color: rgba($color--ocean, 0.1);
    cursor: pointer;
  }
}

.form--button {
  @extend %form--input;
  appearance: none;
  background-color: rgba($color--grass, 0.85);
  color: white;
  cursor: pointer;
  &:focus {
    background-color: rgba($color--grass, 1.00);
    border-bottom-width: 1px;
    border-top-width: 2px;
  }
  &:disabled {
    background-color: rgba($color--space, 0.1);
    border-bottom-width: 1px;
    border-color: rgba($color--space, 0.2);
    border-top-width: 1px;
    color: #ccc;
    cursor: not-allowed;
  }
}
